import React from 'react'
import Loader from './Loader'
import styled from 'styled-components'
import '../chart.scss'

const LoginButton = styled.button`
    position:absolute;
    display:flex;
    justify-content:center;
    align-items:center;
    right:-0.25em;
    translate:0 -50%;
    outline:none;
    border:none;
    /* background-color:var(--gold); */
    background-color:transparent;
    color:transparent;
    border-radius: 50%;
    width:0.75em;
    height:0.75em;

    &:hover {
        cursor:pointer;
    }

`

const Bar = ({data, barHeight, barWidth, isAuthorized, openModal, setActiveOrganization}) => {

    const style = {
        height: barHeight > 0 ? barHeight + '%' : 0,
        width: 'min(15%, ' + barWidth + '%)'
    }

    return (
        <div className="bar" style={style} onClick={() => setActiveOrganization(!isAuthorized ? data.id : null)}>
            <h4 className="text-center">{data.amount}</h4>
            {isAuthorized && (
                <button onClick={() => openModal(data)}>
                    +
                </button>
            )}
        </div>
    )
}

export default function Chart({organizations, isAuthorized, openModal, isLoading, setShowLoginModal, setActiveOrganization}) {
    
    let maxValue = Math.max(...organizations.map(org => org.amount));

    return (
        <div className="chart">
            {isLoading && (
                <Loader />
            )}
            
            <div className="bar-holder">
                {
                organizations.map((org) => 
                    <Bar
                        key={org.id}
                        data={org}
                        barHeight={(org.amount / maxValue) * 100}
                        barWidth={(100 / organizations.length)}
                        isAuthorized={isAuthorized}
                        openModal={openModal}
                        setActiveOrganization={() => setActiveOrganization(org.id)}
                    /> 
                )}
            </div>
            <div className="border-bottom">
                {!isAuthorized && (
                    <LoginButton onClick={() => setShowLoginModal(true)}>+</LoginButton>
                )}
            </div>
            <div className="label-holder">
                {organizations.map((org) => 
                    <h4 key={org.id} className="text-center" onClick={() => setActiveOrganization(org.id)}>{org.name}</h4>
                )}
            </div>
        </div>
    )
}
