import useSWR from 'swr'

const fetcher = (...args) => fetch(...args).then(res => res.json())

export default function GetOrganizationData ({year}) {
    const { data, error, isValidating, mutate } = useSWR(`https://chart.kjeldskov.dk/api/GetOrganizationData.php?year=${year}`, fetcher)

    if (error) return {data: [], mutate}
    if (!data) return {data: [], mutate}

    return { data: data, loading: !error && !data, mutate }
}