import axios from "axios";
import React, {useState, useEffect} from "react";
import Chart from "./components/Chart";
import Modal from "./components/Modal";
import GetAuth from "./api/GetAuth";
import GetOrganizationData from './api/GetOrganizationData'
import Dropdown from "./components/Dropdown";
import CountUp from "react-countup";

let formatter = new Intl.NumberFormat('da-DK', {
    style: 'currency',
    currency: 'DKK',
    minimumFractionDigits: 0
})

const currentYear = new Date().getFullYear();

function Main() {

    const [password, setPassword] = useState([]);
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [currentOrganization, setCurrentOrganization] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [dropdownValues, setDropdownValues] = useState({
        selected: 0,
        values: [
            {id: 0, year:2023},
        ]
    });

    const {data, loading, mutate} = GetOrganizationData(dropdownValues.values[dropdownValues.selected]);

    const [fetchedData, setFetchedData] = useState();

    useEffect(() => {
        if(!data?.organizations) return;
        setFetchedData(data);
    }, [data])

    let defaultPaymentData = {
        organization_id: null,
        amount: 1000,
        date: "",
        case_number: "",
        initials: "",
        address: ""
    }

    let fields = { amount:"beløb", date:"dato", case_number:"sagsnummer", initials:"initialer", address:"address" }

    const [paymentData, setPaymentData] = useState(defaultPaymentData)

    const auth = GetAuth();

    const handleLogin = () => {
        axios.post("https://chart.kjeldskov.dk/api/Authorize.php", {
            password: password,
        }, {withCredentials: true})
        .then(res => {
            if (res.data.status === "success") {
                setIsAuthorized(true);
                setShowLoginModal(false);
            }
        })
    }

    useEffect(() => {
        if(auth.status === "success") {
            setIsAuthorized(true);
        }
    }, [auth])

    const handlePaymentDataChnage = (e) => {

        if(e.target.value === "") {
            e.target.classList.add("error");
        } else {
            e.target.classList.remove("error");
        }

        setPaymentData({
            ...paymentData,
            organization_id: currentOrganization.id,
            [e.target.name]: e.target.value
        })

    }

    const openModal = (organization) => {

        setPaymentData(defaultPaymentData);
        setErrorMessage("");
        setCurrentOrganization(organization);

    }

    const handleSave = () => {

        let empty = findEmptyFields();

        if(empty.length > 0) {
            setErrorMessage("Udfyld " +  findEmptyFields() + " for at registrere");

        } else {
            axios.post("https://chart.kjeldskov.dk/api/SavePayment.php", {
                ...paymentData,
            }, {withCredentials: true})
            .then(res => {
                if(res.data?.status === "success"){
                    setCurrentOrganization(null);
                    setErrorMessage("");
                    mutate();
                } else if(res.data?.status === "error") {
                    setErrorMessage(res.data.message);
                }
            }).catch(err => {
                setErrorMessage("Der skete en fejl. Prøv igen senere.");
            })
        }
    }

    const handleEnter = (e) => {
        if(e.key === "Enter") {
            handleLogin();
        }
    }

    const findEmptyFields = () => {
        let emptyFields = [];

        for (const [key, value] of Object.entries(paymentData)) {
            if(value === "") {
                emptyFields.push(fields[key]);
            }
        }

        return emptyFields.join(", ");
    }

    const [activeOrganization, setActiveOrganization] = useState(null);

    useEffect(() => {

        let timeout 

        // set activeOrganization to null after 3 seconds if it has been set
        if(activeOrganization) {
            timeout = setTimeout(() => {
                setActiveOrganization(null);
            }, 5000)
        }

        return () => {
            clearTimeout(timeout);
        }

    }, [activeOrganization])


    return (
        <div className="wrapper">
            <div className="container">
                <div id="block">
                    
                    

                    {!activeOrganization && (
                        <div className="main-text">
                            <h2>Hos Kjeldskov støtter vi de lokale ildsjæle</h2>
                            <p>Vi donerer 1.000,- kr. for hver bolig vi får til salg – og det bliver til mange penge.
                                <br />
                            Støtten fortsætter til 31/7-2025. 
                            </p>
                            {/* <div className="button sand-bg"><a className="grey" href="/">Læs mere</a></div> */}
                        </div>
                    )}

                    {activeOrganization && (

                        <div className="item">
                            <img src={data?.organizations?.find(o => o.id === activeOrganization).logo} alt="logo" />
                            <div className="item-name">
                                <h2>{data?.organizations?.find(o => o.id === activeOrganization).name}</h2>
                                <div className="button sand-bg"><a className="grey auto" href={data?.organizations?.find(o => o.id === activeOrganization).link} target="_blank" rel="noreferrer">Gå til website</a></div>

                            </div>
                            <div />
                        </div>
                    )}
                </div>
                
                {/* <Dropdown values={dropdownValues} setValues={setDropdownValues} totalAmount={formatter.format(fetchedData?.total_amount ?? 0)}/> */}
                <Dropdown values={dropdownValues} setValues={setDropdownValues} totalAmount={
                    <>
                        {fetchedData?.total_amount !== 0 && (
                            <CountUp
                                start={0}
                                end={(fetchedData?.total_amount)} 
                                duration={6} 
                                separator="." 
                                suffix=" KR."
                                easingFn={(t, b, c, d) => -c/2 * (Math.cos(Math.PI*t/d) - 1) + b }
                                enableScrollSpy={true}
                                scrollSpyOnce={true}
                                scrollSpyDelay={0.5}
                            />
                        )}
                    </>
                }/>
                <Chart isAuthorized={isAuthorized} openModal={openModal} organizations={fetchedData?.organizations ?? []} isLoading={loading} setShowLoginModal={setShowLoginModal} setActiveOrganization={setActiveOrganization} />

                {showLoginModal && (
                    <Modal setState={setShowLoginModal}>
                        <h3>Log ind og angiv støtte</h3>
                        <div className="input-container">
                            <label htmlFor="password-input">Password</label>
                            <input id="password-input" type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={handleEnter}/>
                        </div>
                        <button onClick={handleLogin}>Log ind</button>
                    </Modal>
                )}

                {currentOrganization && (
                    <Modal setState={setCurrentOrganization}>
                        <h3>Angiv støtte til {currentOrganization.name}</h3>
                        <div className="payment">
                            <div className="input-container">
                                <label htmlFor="amount-input">Beløb</label>
                                <input id="amount-input" type="number" placeholder="Beløb" name="amount" onChange={handlePaymentDataChnage} value={paymentData.amount} />
                            </div>
                            <div className="input-container">
                                <label htmlFor="date-input">Dato</label>
                                <input id="date-input" type="date" placeholder="Dato" name="date" onChange={handlePaymentDataChnage} value={paymentData.date} />
                            </div>
                            <p></p>
                            <div className="input-container">
                                <label htmlFor="case-input">Sagsnr.</label>
                                <input id="case-input" type="text" maxLength="7" placeholder="Sagsnummer" name="case_number" onChange={handlePaymentDataChnage} value={paymentData.case_number} />
                            </div>
                            <div className="input-container">
                                <label htmlFor="case-input">Initialer</label>
                                <input id="case-input" type="text" placeholder="Initialer" name="initials" onChange={handlePaymentDataChnage} value={paymentData.initials} />
                            </div>
                            
                        </div>
                        <div className="input-container address">
                            <label htmlFor="case-input">Adresse</label>
                            <input id="case-input" type="text" placeholder="Adresse" name="address" onChange={handlePaymentDataChnage} value={paymentData.address} />
                        </div>
                        {errorMessage && (
                            <h5 className="error-message">{errorMessage}</h5>
                        )}
                        <button onClick={handleSave}>REGISTRÉR OG OPDATER</button>
                    </Modal>
                )}
            </div>
        </div>
    );
}

export default Main;
