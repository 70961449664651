import React, {useState, useRef} from 'react'
import styled from 'styled-components'
import useOutsideAlerter from '../hooks/useOutsideAlerter'
import {IoIosArrowDown as Arrow} from 'react-icons/io'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top:1rem;
    width: 100%;
    position: relative;
    user-select: none;
    max-width:50%;

    * {
        white-space: nowrap;
    }

    @media (max-width: 1140px) {
        max-width:38%;
    }

    @media (max-width: 770px) {
        max-width: 100%;
    }

`

const Value = styled.h4`
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1.5rem;
    color: white;
    padding:0.5rem 0rem;
    line-height:1.2;

    span {
        font-weight: 700;
        font-size:1.5em;
    }

    @media (max-width: 1140px) {
        font-size: calc(2.0vw);
    }

    @media (max-width: 770px) {
        font-size:min(1.25rem, 5vw);
    }
`

const Option = styled.h4`
    text-transform: uppercase;
    font-weight: 400;
    color: white;
    padding:0.75rem 1rem;

    
    &:hover {
        cursor: pointer;
        background-color: var(--background-color-hover);
    }
    
    background-color: ${props => props.selected ? 'var(--background-color-hover)' : 'var(--background-color-lighter)'};
`

const ValueHolder = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding-right:1em;

    &:hover {
    }

    svg {
        fill:var(--gold);
        height:1.5em;
        width:1.5em;
        margin-left:0.5em;
        transition:rotate 0.1s ease-out;
        &.expanded {
            rotate: 180deg;
        }
    }
`

const Options = styled.div`
    width: 100%;
    top:4rem;
    position:absolute;
    z-index: 1000;
    background-color: var(--background-color-lighter);
`

const Dropdown = ({totalAmount, values, setValues}) => {

    const containerRef = useRef();
    const [expanded, setExpanded] = useState(false);

    return (
        <Container ref={containerRef}>
            <ValueHolder>
                <Value>
                    Aktuel støtte i sæsonen 24 / 25:<br/><span> {totalAmount}</span>
                    
                </Value>
            </ValueHolder>
        </Container>
    )
}

export default Dropdown