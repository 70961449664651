import useSWR from 'swr'

const fetcher = (...args) => fetch(...args, {credentials:"include"}).then(res => res.json())

export default function GetAuth () {
    const { data, error, mutate } = useSWR('https://chart.kjeldskov.dk/api/Authorize.php', fetcher)

    if (error) return []
    if (!data) return []

    return data
}