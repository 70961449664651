import React from 'react'
import {MdClose} from 'react-icons/md'

export default function Modal({children, setState}) {
    return (
        <div className={`modal-container`}>
            <div className="overlay" onClick={() => setState(false)} />
            <div className={`modal`}>
                <button className="close" onClick={() => setState(false)}><MdClose /></button>
                
                {children}
            </div>
        </div>
    )
}
