import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
    position:absolute;
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:auto;
    user-select: none;
    background-color: var(--background-color-transparent);
    backdrop-filter: blur(5px);
    z-index: 8;

    h3 {
        color:white !important;
    }
`

const Loader = () => {
    return (
        <Container>
            <h3>Loader...</h3>
        </Container>
    )
}

export default Loader

// Language: javascript
// loader component
